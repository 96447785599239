import { Http } from "../http"

const http = new Http()

export function createOrder(methodType, cartId, ip, utmSource, utmMedium) {
  return new Promise((resolve, reject) => {
    http.post(
      "orders",
      {
        method_type: methodType,
        cart_id: cartId,
        ip: ip,
        utm_source: utmSource,
        utm_medium: utmMedium,
      },
      (err, res) => {
        err
          ? reject(err)
          : resolve({
              state: "success",
              orderId: res.data.id,
            })
      }
    )
  })
}

export function getOrderByReference(reference) {
  return new Promise(resolve => {
    http.get(
      "orders/public/reference",
      {
        reference,
      },
      (err, res) => {
        err
          ? resolve(err)
          : resolve({
              state: "success",
              orderInfo: res.data,
            })
      }
    )
  })
}

export function createTransaction(
  orderId,
  paymentId,
  cardBrand,
  cardFunding,
  cardCountry,
  cardLast4
) {
  return new Promise((resolve, reject) => {
    http.post(
      "transactions/payments",
      {
        orderId: orderId,
        token: paymentId,
        card_brand: cardBrand,
        card_funding: cardFunding,
        card_country: cardCountry,
        card_last: cardLast4,
      },
      (err, res) => {
        err
          ? reject(err)
          : resolve({
              state: "success",
              info: res.data,
            })
      }
    )
  })
}

export function createCryptoTransaction(
  orderId
) {
  return new Promise((resolve, reject) => {
    http.post(
      "payment/coinbase",
      {
        orderId: orderId
      },
      (err, res) => {
        err
          ? reject(err)
          : resolve({
            state: "success",
            info: res.data,
          })
      }
    )
  })
}

export function confirmPayment(
  transactionId,
  paymentIntentId,
  paymentStatus,
  confirmedCardResult
) {
  return new Promise((resolve, reject) => {
    http.post(
      "transactions/payments/confirmation/stripe",
      {
        transaction_id: transactionId,
        payment_intent_id: paymentIntentId,
        status: paymentStatus,
        provider_response: confirmedCardResult,
      },
      (err, res) => {
        err
          ? reject(err)
          : resolve({
              state: "success",
              data: res.data,
            })
      }
    )
  })
}
