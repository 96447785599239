import "regenerator-runtime/runtime"
import { createCryptoTransaction, createTransaction } from "../domain/datasources/orders.remote"

export const handleCreateNewTransaction = async (
  orderId,
  paymentId,
  cardBrand,
  cardFunding,
  cardCountry,
  cardLast4,
  callback
) => {

    return await createTransaction(
      orderId,
      paymentId,
      cardBrand,
      cardFunding,
      cardCountry,
      cardLast4
    )


}

export const handleCreateNewCryptoTransaction = async (
  orderId,
) => {

    return await createCryptoTransaction(orderId)
}
