import React, { useEffect, useState } from "react"
import { Checkbox, Select } from "antd"
import { currencyToSymbol } from "../../../../data/managers/currencytosymbol"
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from "@stripe/react-stripe-js"

const CheckoutInfo = props => {
  const { Option } = Select
  const stripe = useStripe()
  const elements = useElements()
  const [showUpSell, setShowUpSell] = useState(true)
  const [selectedFollowers, setSelectedFollowers] = useState(null)
  const [selectedLikes, setSelectedLikes] = useState(null)
  const [selectedViews, setSelectedViews] = useState(null)

  const [addedFollowers, setAddedFollowers] = useState(false)
  const [addedLikes, setAddedLikes] = useState(false)
  const [addedViews, setAddedViews] = useState(false)


  useEffect(() => {
    if (
      props.cartInfo &&
      props.cartInfo.cartItems &&
      props.cartInfo.cartItems[0].category === "soundcloud" &&
      props.cartInfo.cartItems[0].subCategory === "followers"
    ) {
      setShowUpSell(false)
    }
  })

  const selectFirstProduct = (category) => {
    switch (category.categoryName) {
      case "views":
        return selectedViews
      case "likes":
        return selectedLikes
      case "followers":
        return selectedFollowers
    }
  }

  const selectRelatedText = (category) => {
    switch (category) {
      case "views":
        return "Boost your visibility with a tailored views package to amplify your content's reach."
      case "likes":
        return "Enhance your engagement with a custom likes package, designed to increase interaction with your content."
      case "followers":
        return "Complete your order with a custom followers package, designed to enhance your growth and reach even further."
      default:
        return ""
    }
  }

  const selectRelatedImg = (category) => {
    switch (category) {
      case "views":
        return "/img/tklogo.png"
      case "likes":
        return "/img/tklogo.png"
      case "followers":
        return "/img/tklogo.png"
      default:
        return ""
    }
  }

  const changeSelectedProduct = (category, value) => {
    switch (category.categoryName) {
      case "views":
        setSelectedViews(value)
        break
      case "likes":
        setSelectedLikes(value)
        break
      case "followers":
        setSelectedFollowers(value)
        break
    }
  }

  const prepareAddItemToCart = (category) => {
    let product
    switch (category.categoryName) {
      case "views":
        product = category.relatedProducts.find(item => item.sku === selectedViews)
        props.addItemToCart(product)
        setAddedViews(true)
        break
      case "likes":
        product = category.relatedProducts.find(item => item.sku === selectedLikes)
        props.addItemToCart(product)
        setAddedLikes(true)
        break
      case "followers":
        product = category.relatedProducts.find(item => item.sku === selectedFollowers)
        props.addItemToCart(product)
        setAddedFollowers(true)
        break
    }
  }

  const prepareRemoveItemToCart = (category) => {
    let product
    switch (category.categoryName) {
      case "views":
        product = props.cartInfo.cartItems.find(item => item.sku === selectedViews)
        props.deleteItemFromCart(product)
        setAddedViews(false)
        break
      case "likes":
        product = props.cartInfo.cartItems.find(item => item.sku === selectedLikes)
        props.deleteItemFromCart(product)
        setAddedLikes(false)
        break
      case "followers":
        product = props.cartInfo.cartItems.find(item => item.sku === selectedFollowers)
        props.deleteItemFromCart(product)
        setAddedFollowers(false)
        break
    }
  }

  const checkIsAlreadyAdded = (category) => {
    switch (category) {
      case "views":
        return addedViews ? "product-added" : "product-not-added"
      case "likes":
        return addedLikes ? "product-added" : "product-not-added"
      case "followers":
        return addedFollowers ? "product-added" : "product-not-added"
    }
  }

  const selectProductToRemoveText = (category) => {
    let product
    switch (category.categoryName) {
      case "views":
        product = category.relatedProducts.find(item => item.sku === selectedViews)
        break
      case "likes":
        product = category.relatedProducts.find(item => item.sku === selectedLikes)
        break
      case "followers":
        product = category.relatedProducts.find(item => item.sku === selectedFollowers)
        break
    }
    if (!product) {
      return ""
    }
    return "You have been added " + product.times + " " + product.subCategory + " to your order"
  }


  useEffect(() => {
    let cartItem
    for (let category of props.relatedProductsList) {
      cartItem = props.cartInfo.cartItems.find(item => item.subCategory === category.categoryName)
      switch (category.categoryName) {
        case "views":
          if (cartItem) {
            setSelectedViews(cartItem.sku)
            setAddedViews(true)
          } else {
            setSelectedViews(category.relatedProducts[0].sku)
          }
          break
        case "likes":
          if (cartItem) {
            setSelectedLikes(cartItem.sku)
            setAddedLikes(true)
          } else {
            setSelectedLikes(category.relatedProducts[0].sku)
          }
          break
        case "followers":
          if (cartItem) {
            setSelectedFollowers(cartItem.sku)
            setAddedFollowers(true)
          } else {
            setSelectedFollowers(category.relatedProducts[0].sku)
          }
          break
      }
    }

  }, [props.relatedProductsList])

  return (
    <div>
      <div className="text-gray-700">
        <div style={{ width: "100%" }}>
          <div className="flex flex-wrap justify-center items-center pricing-ins go-previous"
               style={{ textAlign: "left", marginBottom: "20px", float: "left" }} onClick={props.goPreviousStep}>
            <i className="fa fa-arrow-left" style={{ marginRight: "5px" }}></i>
          </div>
        </div>
        <div>
          {props.coverInfo && (
            <div className="flex checkout-card"
                 style={{ flexDirection: "column", paddingTop: "0", paddingBottom: "0", width: "100%" }}>

              <div className="flex">
                <div
                  style={{ borderWidth: "0px" }}
                  className="flex items-center px-3 py-2 border-2 rounded-md border-gray-200 focus:outline-none focus:border-gray-500 transition-colors"
                >
                  {props.coverInfo.avatar && (
                    <div className="flex items-center">
                      <div style={{ borderRadius: "5px", overflow: "hidden" }}>
                        <img src={props.coverInfo.avatar} className="w-12 h-12" />
                      </div>


                      <div className="ml-2" style={{ textAlign: "left", paddingTop: "16px" }}>
                        <p style={{ fontSize: "17px", fontWeight: "500", margin: 0 }}>{props.userName}

                          {props.coverInfo.nickname && (<span style={{
                            fontWeight: "500",
                            fontSize: "14px"
                          }}> (@{props.coverInfo.nickname})</span>)}
                        </p>
                        <p style={{
                          fontWeight: "500",
                          color: "gray"
                        }}>{props.userEmail}</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <table style={{ width: "100%" }} className="checkout-table">
                  <tbody>
                  {props.cartInfo.cartItems && (
                    props.cartInfo.cartItems.map(item => (
                      <tr key={item.id}>
                        <td>{item.times} {item.subCategory}</td>
                        <td style={{ textAlign: "right" }}><b>$ {item.amount}</b></td>
                      </tr>
                    ))
                  )}
                  <tr style={{ borderTop: "1px solid #E2E8F0" }}>
                    <td>
                      Subtotal
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <b>$ {props.cartInfo.subTotal}</b>
                    </td>
                  </tr>
                  {props.cartInfo.totalDiscount > 0 && (
                    <tr key="totalDis">
                      <td>
                        Discount
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <b>$ {props.cartInfo.totalDiscount}</b>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>
                      Tax
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <b>Included</b>
                    </td>
                  </tr>
                  <tr style={{ borderTop: "1px solid #E2E8F0", fontSize: "18px" }} key="totalAmount">
                    <td>
                      <b>Total Price</b>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <b>$ {props.cartInfo.totalAmount}</b>
                    </td>
                  </tr>
                  </tbody>
                </table>


              </div>
            </div>
          )}


          {showUpSell === true && (
            <div>

              {props.relatedProductsList &&
                props.relatedProductsList.map(category => (
                  <div className={checkIsAlreadyAdded(category.categoryName)}>
                    <div className={`flex checkout-card checkout-select`}
                         style={{ flexDirection: "column" }}>
                      <div style={{ width: "100%", textAlign: "left", fontSize: "20px" }} className="checkout-title">
                        <h2><img src={selectRelatedImg(category.categoryName)}
                                 style={{
                                   width: "20px",
                                   display: "inline",
                                   position: "relative",
                                   top: "-3px"
                                 }} /> Boost
                          your <span style={{ color: "#FE2C55" }}>{category.categoryName}</span></h2>
                        <p style={{
                          fontSize: "16px",
                          margin: 0,
                          padding: 0,
                          marginBottom: "10px",
                          marginTop: "15px"
                        }}>{selectRelatedText(category.categoryName)}</p>
                      </div>
                      <div>
                        <Select
                          className="ant-select-no-styles"
                          onChange={value => changeSelectedProduct(category, value)}
                          value={selectFirstProduct(category)}
                        >

                          {category &&
                            category.relatedProducts &&
                            category.relatedProducts.map(product => (
                              <Option value={product.sku} key={product.sku}>
                                <div className="flex justify-between">
                                  <div>
                                    <b>{product.times}{" "}
                                      <span className="capitalize">
                            {product.subCategory}
                          </span>
                                    </b>
                                  </div>
                                  <div>
                                    <b>
                                      {currencyToSymbol(product.currency)}{product.amount}
                                    </b>
                                  </div>
                                </div>
                              </Option>
                            ))}
                        </Select>
                        <button
                          onClick={() => prepareAddItemToCart(category)}
                          className="rounded-half py-1 px-8 ant-btn ant-btn-primary checkout-button w-half red-hover"
                          style={{ marginTop: "20px", float: "left" }}

                        >
                          <span style={{ marginLeft: "5px" }}> Add to cart</span>
                        </button>
                      </div>
                    </div>
                    <div className="checkout-remove">
                      <div className={`flex checkout-card`}
                           style={{ flexDirection: "column" }}>
                        <div style={{ width: "100%", textAlign: "left", fontSize: "20px" }} className="checkout-title">
                          <h2><img src={selectRelatedImg(category.categoryName)}
                                   style={{
                                     width: "20px",
                                     display: "inline",
                                     position: "relative",
                                     top: "-3px"
                                   }} /> Boost
                            your <span style={{ color: "#FE2C55" }}>{category.categoryName}</span></h2>
                          <p style={{
                            fontSize: "16px",
                            margin: 0,
                            padding: 0,
                            marginBottom: "10px",
                            marginTop: "15px"
                          }}>{selectProductToRemoveText(category)}</p>
                        </div>
                        <button
                          onClick={() => prepareRemoveItemToCart(category)}
                          className="rounded-half py-1 px-8 ant-btn ant-btn-primary checkout-button w-half red-hover"
                          style={{ marginTop: "20px", float: "left" }}

                        >
                          <span style={{ marginLeft: "5px" }}> Remove</span>
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
      <div className="flex checkout-card" style={{ flexDirection: "column", marginTop: "20px" }}>
        <div className="flex">
          <h1 className="text-left font-bold text-xl" style={{ marginBottom: 0, paddingBottom: 0 }}>
            Secure Checkout
          </h1>
        </div>

        <div style={{ marginTop: "20px", display: "none" }} id="card-container"></div>
        <div
          className="ant-alert ant-alert-error mt-10"
          style={{
            display: "none",
            marginTop: "15px"
          }}
        >
          {props.paymentError}
        </div>

        <div>
          <div className="block w-full mx-auto text-center text-white">
            <div className="" style={{ width: "100%", marginBottom: "20px", marginTop: "20px" }}>
              <div className="cardBlock">
                <label style={{ fontSize: "14px" }} className="cardSpan">
                  <img src="/img/CreditCard.png" /> Card Number
                </label>
                <CardNumberElement
                  className="input "
                  options={{
                    supportedPaymentMethods: ["card", "link"],
                    style: {
                      base: {
                        fontSize: "15px",
                        color: "#424770",
                        border: "1px solid #CBD5E1",
                        fontWeight: "bold",
                        "::placeholder": {
                          color: "#94A3B8"
                        }
                      },
                      invalid: {
                        color: "#9e2146"
                      }
                    }
                  }} />
              </div>
              <div className="half cardBlock">
                <label style={{ fontSize: "14px" }} className="cardSpan">
                  <img src="/img/CalendarBlank.png" /> Expiration
                </label>
                <CardExpiryElement
                  className="input"

                  options={{
                    style: {
                      base: {
                        fontSize: "15px",
                        color: "#94A3B8",
                        fontWeight: "bold",
                        "::placeholder": {
                          color: "#aab7c4"
                        }
                      },
                      invalid: {
                        color: "#9e2146"
                      }
                    }
                  }}
                />
              </div>
              <div className="half ml2 cardBlock">
                <label style={{ fontSize: "14px" }} className="cardSpan">
                  <img src="/img/Password.png" /> CVV/CVC</label>
                <CardCvcElement
                  className="input"
                  options={{
                    style: {
                      base: {
                        fontSize: "15px",
                        color: "#94A3B8",
                        fontWeight: "bold",
                        "::placeholder": {
                          color: "#aab7c4"
                        }
                      },
                      invalid: {
                        color: "#9e2146"
                      }
                    }
                  }}
                />
              </div>
              <small
                style={{ color: "gray", fontSize: "13px", marginBottom: "10px", textAlign: "left", display: "block" }}>
                *CVV number is a 3 digit numeric code behind the card
              </small>
            </div>

            {props.stripeErrorMessage && (
              <div className="alert alert-warn">
                {props.stripeErrorMessage}
              </div>
            )}


            {props.loading === true ? (
              <button className="rounded-full py-1 px-8 ant-btn ant-btn-primary checkout-button w-full">
                {!props.loading
                  ? ` Pay
                ${
                    props.cartInfo
                      ? currencyToSymbol(props.cartInfo.currency)
                      : ""
                  }${props.cartInfo ? props.cartInfo.totalAmount : ""}`
                  : "Processing..."}
              </button>
            ) : (
              <div>
                <button
                  className="rounded-full py-1 px-8 ant-btn ant-btn-primary checkout-button w-full"
                  onClick={() =>
                    props.createNewOrder()
                  }
                >
                  Pay{" "}
                  {props.cartInfo
                    ? currencyToSymbol(props.cartInfo.currency)
                    : ""}
                  {props.cartInfo ? props.cartInfo.totalAmount : ""}
                </button>

              </div>
            )}
          </div>

        </div>
      </div>

      {/*<div className="flex checkout-card" style={{ flexDirection: "column", marginTop: "20px" }}>*/}
      {/*  <div className="block w-full mx-auto text-center text-white">*/}
      {/*    <div className="flex">*/}
      {/*      {props.cryptoLoading === true ? (*/}
      {/*        <button className="rounded-full py-1 px-8 ant-btn ant-btn-primary checkout-button w-full">*/}
      {/*          Processing...*/}
      {/*        </button>*/}
      {/*      ) : (*/}
      {/*        <button*/}
      {/*          className="rounded-full py-1 px-8 ant-btn ant-btn-white checkout-button w-full"*/}
      {/*          onClick={() =>*/}
      {/*            props.createNewOrderWithCrypto()*/}
      {/*          }*/}
      {/*        >*/}
      {/*          <img src="/img/crypto.png" width="25px"*/}
      {/*               style={{ display: "inline", position: "relative", top: "-2px" }} /> <span>Pay with Crypto</span>*/}

      {/*        </button>*/}
      {/*      )}*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  )
}

export default CheckoutInfo
