/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import { CaretDownOutlined } from "@ant-design/icons"
import { handleGetCartIdFromCookie } from "../../../data/usecases/getcartidfromcookie"
import { handleCartInfo } from "../../../data/usecases/getcartinfo"
import { handleGetUpSellProductsList } from "../../../data/usecases/getupsellprodcutslist"
import { handleGetRelatedProductsList } from "../../../data/usecases/getrelatedproductslist"
import { handleReplaceItemFromCart } from "../../../data/usecases/replaceitemfromcart"
import { handleUpdateCart } from "../../../data/usecases/updatecart"
import { handleAddItemsToCart } from "../../../data/usecases/additemstocart"
import { handleDeleteItemFromCart } from "../../../data/usecases/deleteitemfromcart"
import { handleCreateNewOrder } from "../../../data/usecases/createneworder"
import { handleUserIp } from "../../../data/usecases/getuserip"
import { handleValidateCoupon } from "../../../data/usecases/validatecoupon"
import { handleCreateCart } from "../../../data/usecases/createnewcart"
import { getQueryParam } from "../../../data/managers/getqueryparam"
import Layout from "../../chunks/layout"
import UserInfo from "./components/userinfo"
import CheckoutInfo from "./components/checkoutinfo"
import { Helmet } from "react-helmet"
import { notification } from "antd"
import { navigate } from "gatsby"
import { useLocation } from "@reach/router"
import TrustIcons from "../../../static/img/checkout-methods.png"
import RealBoostLogo from "./components/realboostlogo"
import { useCookies } from "react-cookie"
import {
  useStripe,
  useElements,
  CardNumberElement
} from "@stripe/react-stripe-js"
import {
  handleCreateNewCryptoTransaction,
  handleCreateNewTransaction
} from "../../../data/usecases/createnewtransaction"
import { handleConfirmPayment } from "../../../data/usecases/confirmpayment"
import Footer from "../../components/footer"
import { handlerGetTikTokCover } from "../../../data/usecases/get_tiktok_cover"
import PostInfo from "./components/postinfo"


const Checkout = () => {

  const stripe = useStripe()
  const elements = useElements()
  const [isTermsOfServicesChecked, setIsTermsOfServicesChecked] = useState(
    false
  )
  const [cartInfo, setCartInfo] = useState([])
  const [userIp, setUserIp] = useState(null)
  const [upSellProductsList, setUpSellProductsList] = useState([])
  const [relatedProductsList, setRelatedProductsList] = useState([])
  const [selectedProduct, setSelectedProduct] = useState([])
  const [userName, setUserName] = useState(null)
  const [userEmail, setUserEmail] = useState(null)
  const [profileName, setProfileName] = useState(null)
  const [checkoutFormStep, setCheckoutFormStep] = useState(1)
  const [isCouponValid, setIsCouponValid] = useState(false)
  const [validatingCouponError, setValidatingCouponError] = useState(null)
  const [coverInfo, setCoverInfo] = useState(null)
  const [loading, setLoading] = useState(false);
  const [cryptoLoading, setCryptoLoading] = useState(false);
  const location = useLocation()
  const [stripeErrorMessage, setStripeErrorMessage] = useState("")
  const [showCampaingSummary, setShowCampaingSummary] = useState(false)
  const [cookies, setCookie] = useCookies()
  const [paymentError, setPaymentError] = useState(null)
  const [cardInput, setCardInput] = useState(null)
  const [paymentId, setPaymentId] = useState(null)
  const [cardObject, setCardObject] = useState(null)
  const [paymentObject, setPaymentObject] = useState(null)
  const [errorMessage, setErrorMessage] = useState("")
  const [postSelected, setPostSelected] = useState(null)
  const [isPostSelectable, setIsPostSelectable] = useState(false)


  const sendNotification = (type, message, description) => {
    notification[type]({
      message,
      description,
      top: 100
    })
  }

  const getCartInfo = (cartId, coupon) => {
    handleCartInfo(cartId, (error, cartInfo) => {
      if (error) {
        navigate(`/`)
        return sendNotification("error", "Something went wrong!", error)
      }
      if (cartInfo && cartInfo.cartItems.length > 0) {
        setCartInfo(cartInfo)
        setSelectedProduct(cartInfo.cartItems[0])

        const cookieEmail = cookies["customer.email"]
          ? cookies["customer.email"]
          : ""
        const cookieUsername = cookies["customer.username"]
          ? cookies["customer.username"]
          : ""
        const cookieUrl = cookies["customer.url"] ? cookies["customer.url"] : ""

        setUserEmail(cartInfo.userEmail ? cartInfo.userEmail : cookieEmail)
        setUserName(cartInfo.userName ? cartInfo.userName : cookieUsername)
        setProfileName(cookieUrl)

        if (coupon) {
          validateCoupon(cartInfo.cartId, getQueryParam("cd", location))
        }
      }
    })
  }

  const createNewCartAndAddItem = (itemReference, coupon) => {
    handleCreateCart(itemReference, (error, cartInfo) => {
      if (error) {
        return navigate(`/`)
      }
      document.cookie = `cart_id=${cartInfo.cartId}; path=/`
      getCartInfo(cartInfo.cartId, coupon)
    })
  }

  const validateCoupon = (cartId, coupon) => {
    setValidatingCouponError(null)
    handleValidateCoupon(cartId, coupon, userIp, error => {
      if (error) {
        return setValidatingCouponError(`Invalid coupon: ${coupon}`)
      }
      setIsCouponValid(true)
      getCartInfo(cartId)
    })
  }

  const getRelatedProductsList = cartId => {
    handleGetRelatedProductsList(cartId, (error, productsList) => {
      if (error) {
        return sendNotification("error", "Something went wrong!", error)
      }
      setRelatedProductsList(productsList)
    })
  }

  const changeSelectedProduct = newProductSku => {
    handleReplaceItemFromCart(
      cartInfo.cartId,
      selectedProduct.sku,
      newProductSku,
      error => {
        if (error) {
          return sendNotification("error", "Something went wrong!", error)
        }
        getCartInfo(cartInfo.cartId)
      }
    )
  }

  const handleProcessWithUserInfo = async () => {
    let profile = profileName
    profile = profile.replace("@", "")
    profile = profile.replace("https://www.tiktok.com/", "")
    profile = profile.replace("https://tiktok.com/", "")

    const tiktokProfile = "https://tiktok.com/@" + profile
    setLoading(true)
    setErrorMessage("")

    if (!isTermsOfServicesChecked) {
      setErrorMessage("Terms and conditions should be accepted")
      setLoading(false)
      return
    }

    if (userEmail === "") {
      setErrorMessage("Please enter a valid email")
      setLoading(false)
      return
    }

    let response

    try {
      response = await handlerGetTikTokCover(profile)
      if (response.state === "success") {
        setCoverInfo(response.coverInfo)
        if (response.coverInfo.error) {
          // setErrorMessage("This TikTok username doesn't exist. Please try again.")
          // setLoading(false)
          await handleUpdateCartWithUserInfo(tiktokProfile)
          return
        }
      }
    } catch (error) {
      // setErrorMessage("This TikTok username doesn't exist. Please try again.")
      // setLoading(false)
      await handleUpdateCartWithUserInfo(tiktokProfile)
      return
    }

    setIsPostSelectable(false)
    let isPostSelectable = false
    cartInfo.cartItems.forEach(item => {
      if (item.subCategory === "likes" || item.subCategory === "views" && (response && response.coverInfo && response.coverInfo.videos.length > 0)) {
        isPostSelectable = true
        setIsPostSelectable(true)
      }
    })
    setLoading(false)
    if (!isPostSelectable) {
      await handleUpdateCartWithUserInfo(tiktokProfile)
    } else {
      setCheckoutFormStep(2)
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })

    }

  }

  const handleUpdateCartWithUserInfo = async (targetUrl) => {

    if (userEmail && userName && profileName && targetUrl) {
      handleUpdateCart(
        cartInfo.cartId,
        userEmail,
        userName,
        targetUrl,
        error => {
          setLoading(false)
          if (error) {
            return sendNotification("error", "Something went wrong!", error)
          }
          getCartInfo(cartInfo.cartId)
          getRelatedProductsList(cartInfo.cartId)

          setCheckoutFormStep(3)
          addQueryParamToCurrentUrl("step", "payment")
          window.scrollTo({
            top: 0,
            behavior: "smooth"
          })
        }
      )
    }
  }

  const addQueryParamToCurrentUrl = (param, value) => {
    const currentUrl = new URL(window.location)
    currentUrl.searchParams.set(param, value)
    window.history.pushState({}, "", currentUrl)
  }

  const removeQueryParamFromCurrentUrl = (param) => {
    const currentUrl = new URL(window.location)
    currentUrl.searchParams.delete(param)
    window.history.pushState({}, "", currentUrl)
  }

  const handleSelectPost = (video, e) => {

    const allDivs = document.querySelectorAll(".checkout-post-selected")
    allDivs.forEach((div) => div.classList.remove("checkout-post-selected"))

    if (postSelected && postSelected.id === video.id) {
      setPostSelected(null)
      return
    }

    e.currentTarget.classList.add("checkout-post-selected")
    setPostSelected(video)

  }

  const handleProcessPostSelection = async () => {
    if (!postSelected) {
      return
    }
    await handleUpdateCartWithUserInfo(postSelected.url)
  }

  const addItemToCart = productSelected => {
    if (
      cartInfo.cartItems &&
      cartInfo.cartItems.some(
        item => item.subCategory === productSelected.subCategory
      )
    ) {
      return deleteItemFromCartAndAddNewOne(
        cartInfo.cartItems.find(
          item => item.subCategory === productSelected.subCategory
        ),
        productSelected
      )
    }
    handleAddItemsToCart(cartInfo.cartId, [productSelected.sku], error => {
      if (error) {
        return sendNotification("error", "Something went wrong!", error)
      }
      getCartInfo(cartInfo.cartId)
    })
  }

  const deleteItemFromCartAndAddNewOne = (oldProduct, newProduct) => {
    handleDeleteItemFromCart(cartInfo.cartId, oldProduct.id, error => {
      if (error) {
        return sendNotification("error", "Something went wrong!", error)
      }
      handleAddItemsToCart(cartInfo.cartId, [newProduct.sku], error => {
        if (error) {
          return sendNotification("error", "Something went wrong!", error)
        }
        getCartInfo(cartInfo.cartId)
      })
    })
  }

  const deleteItemFromCart = item => {
    console.log("deleted", item)
    if (item && item.id) {
      handleDeleteItemFromCart(cartInfo.cartId, item.id, error => {
        if (error) {
          return sendNotification("error", "Something went wrong!", error)
        }
        getCartInfo(cartInfo.cartId)
      })
    }
  }

  const getUserIp = async () => {
    const response = await fetch("https://api.ipify.org", {})
    const data = await response.text()
    setUserIp(data)
  }

  const createNewOrder = async (method) => {
    if (!method || method === "") {
      method = "card"
    }
    setLoading(true)
    const utmSource = document.cookie.replace(
      /(?:(?:^|.*;\s*)document_referrer\s*\=\s*([^;]*).*$)|^.*$/,
      "$1"
    )
    const utmMedium = getQueryParam("utm_medium", location)

    const cardElement = elements.getElement(CardNumberElement)
    const res = await stripe.createToken(cardElement)

    if (res.error) {
      setLoading(false)
      if (res.error.message) {
        setStripeErrorMessage(res.error.message)
      } else {
        setStripeErrorMessage("an error has occurred, try later")
      }
      return
    }

    let ip

    if (res) {
      ip = res.token.client_ip
    } else {
      ip = userIp
    }

    try {
      const orderRes = await handleCreateNewOrder(
        method,
        cartInfo.cartId,
        ip,
        utmSource,
        utmMedium
      )
      stripePayment(orderRes.orderId, res.token)

    } catch (error) {
      setLoading(false)
      setStripeErrorMessage("something went wrong!")
    }

  }

  const createNewOrderWithCrypto = async (method) => {
    if (!method || method === "") {
      method = "coinbase"
    }
    setCryptoLoading(true)
    const utmSource = document.cookie.replace(
      /(?:(?:^|.*;\s*)document_referrer\s*\=\s*([^;]*).*$)|^.*$/,
      "$1"
    )
    const utmMedium = getQueryParam("utm_medium", location)


    try {
      const orderRes = await handleCreateNewOrder(
        method,
        cartInfo.cartId,
        userIp,
        utmSource,
        utmMedium
      );
      const response = await handleCreateNewCryptoTransaction(orderRes.orderId);
      window.location.href = response.info.hostedUrl;
    } catch (error) {
      setCryptoLoading(false)
      setStripeErrorMessage("something went wrong!")
    }

  }

  const stripePayment = async (orderId, paymentMethod) => {

    try {
      const response = await handleCreateNewTransaction(
        orderId,
        paymentMethod.id,
        paymentMethod.card.brand,
        paymentMethod.card.funding,
        paymentMethod.card.country,
        paymentMethod.card.last4
      )
      confirmStripePayment(response.info.reference, response.info.transaction_id, paymentMethod)
    } catch (error) {
      setLoading(false)
      setStripeErrorMessage("something went wrong!")
    }


  }

  const confirmStripePayment = async (reference, transactionId, paymentMethod) => {
    const cardElement = elements.getElement(CardNumberElement)
    const stripeResponse = await stripe.confirmCardPayment(
      reference,
      {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: userName,
            email: userEmail
          }
        }
      }
    )

    if (stripeResponse.error) {
      setLoading(false)
      setStripeErrorMessage(stripeResponse.error.message)
      return
    }

    try {
      const result = await handleConfirmPayment(
        transactionId,
        stripeResponse.paymentIntent.id,
        stripeResponse.paymentIntent.status,
        JSON.stringify(stripeResponse)
      )
      if (result.data.status === "success") {
        window.location.href = result.data.redirect
      } else {
        setLoading(false)
        setStripeErrorMessage("we cannot process your payment")
      }
    } catch (error) {
      setLoading(false)
      setStripeErrorMessage("we cannot process your payment")
      return
    }
  }

  const stepSelected = (step) => {

    if (step === 1 && (checkoutFormStep === 1 || checkoutFormStep === 2)) {
      return "process-selected"
    }

    if (step === 2 && (checkoutFormStep === 3)) {
      return "process-selected"
    }

  }

  const goPreviousStep = () => {
    if (isPostSelectable) {
      setCheckoutFormStep(checkoutFormStep - 1)
    } else {
      setCheckoutFormStep(1)
    }

    removeQueryParamFromCurrentUrl("step")

  }

  useEffect(() => {
    if (cartInfo && cartInfo.cartId && checkoutFormStep === 1) {
      handleGetUpSellProductsList(cartInfo.cartId, (error, productsList) => {
        if (error) {
          return sendNotification("error", "Something went wrong!", error)
        }
        setUpSellProductsList(productsList)
      })
    }
  }, [cartInfo])

  useEffect(() => {

  }, [coverInfo])

  useEffect(() => {
    removeQueryParamFromCurrentUrl("step")
    const cardIdFromCookie = handleGetCartIdFromCookie()
    const cardIdFromUrl = getQueryParam("token", location)
    const coupon = getQueryParam("cd", location)
    const itemReference = getQueryParam("ref", location)
    const paymentErr = getQueryParam("paymentError", location)

    if (paymentErr) {
      setPaymentError("Authorization failed")
    }

    getUserIp()

    if (cardIdFromUrl) {
      const rgx = new RegExp("^(?=[a-f\\d]{24}$)(\\d+[a-f]|[a-f]+\\d)*")
      if (cardIdFromUrl.match(rgx)) {
        document.cookie = `cart_id=${cardIdFromUrl}; path=/`
        getCartInfo(cardIdFromUrl, coupon)
        return
      }
    }
    if (cardIdFromCookie) {
      getCartInfo(cardIdFromCookie, coupon)
      return
    }
    if (itemReference) {
      createNewCartAndAddItem(itemReference, coupon)
      return
    }

    navigate(`/`)
  }, [])

  return (
    <Layout showFooter={false}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Checkout | Boostik</title>
        <link rel="canonical" href="https://boostik.io/" />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div
        className="py-5 fixed w-full bg-white z-50 checkout-header"
        style={{ margin: "auto" }}
      >
        <div className="flex  items-center">
          <div className="pl-2 sm:pl-10 w-12/12 sm:w-3/12">
            <RealBoostLogo />
          </div>
          <div className="sm:w-7/12  checkout-steps" style={{ display: "flex", justifyContent: "space-between" }}>
            <div className={`pl-2 sm:pl-10 w-12/12 sm:w-6/12 process ${stepSelected(1)}`}>
              <div>
                <div className="process-number">1</div>
                Information
              </div>
            </div>
            <div className="pl-2  w-12/12 sm:w-5/12 process process-separator">
              <img src="/img/separator.png" />
            </div>
            <div className={`pl-2  w-12/12 sm:w-5/12 process ${stepSelected(2)}`}>
              <div>
                <div className="process-number">2</div>
                Payment
              </div>
            </div>
            <div className="pl-2  w-12/12 sm:w-5/12 process process-separator">
              <img src="/img/separator.png" />
            </div>
            <div className="pl-2  w-12/12 sm:w-5/12 process">
              <div>
                <div className="process-number">3</div>
                Complete
              </div>
            </div>
          </div>

          <div
            className="sm:inline w-3/12 text-xl text-center color-home checkout-close"
            style={{ textAlign: "left", fontSize: "17px", marginTop: "-1px", marginRight: "30px" }}
            onClick={() => navigate("/")}
          >
            <div className="checkout-close-btn">
              <i className="fa fa-plus" style={{ transform: "rotate(45deg)" }}></i>
            </div>
          </div>
        </div>
      </div>

      <div id="checkout-section">
        <div className="flex justify-between">
          <div className="w-12/12  mt-30 sm:mt-28" style={{ width: "100%" }}>
            <center className="flex checkout-inner" style={{ flexDirection: "column" }}>
              <div className={`section-title sm:w-6/12 checkout-content`}
                   style={{ fontSize: "35px", marginBottom: "20px" }}>
                {checkoutFormStep === 1 && (<div>Information & Package</div>)}
                {checkoutFormStep === 2 && (<div>Information & Package</div>)}
                {checkoutFormStep === 3 && (<div>Summary & Payment</div>)}
              </div>
              <div className="flex flex-wrap justify-center items-center pricing-ins" style={{ marginBottom: "20px" }}>
                <div>
                  <p><img src="/img/check.png" style={{ display: "inline" }} />
                    100% Safe</p>
                </div>
                <div>
                  <p><img src="/img/check.png" style={{ display: "inline" }} />
                    No password required</p>
                </div>
                <div>
                  <p><img src="/img/check.png" style={{ display: "inline" }} />
                    Encrypted & Secure payment process</p>
                </div>
              </div>

              {checkoutFormStep === 1 && (
                <div className="lg:w-5/12 md:w-6/12 sm:w-8/12 px-5 checkout-content">
                  <UserInfo
                    selectedProduct={selectedProduct}
                    changeSelectedProduct={changeSelectedProduct}
                    upSellProductsList={upSellProductsList}
                    userEmail={userEmail}
                    setUserEmail={setUserEmail}
                    userName={userName}
                    setUserName={setUserName}
                    profileName={profileName}
                    setProfileName={setProfileName}
                    handleProcessWithUserInfo={handleProcessWithUserInfo}
                    isCouponValid={isCouponValid}
                    validatingCouponError={validatingCouponError}
                    coverInfo={coverInfo}
                    setCoverInfo={setCoverInfo}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    loading={loading}
                    isTermsOfServicesChecked={isTermsOfServicesChecked}
                    setIsTermsOfServicesChecked={setIsTermsOfServicesChecked}
                  />
                </div>
              )}

              {checkoutFormStep === 2 && (
                <div className="lg:w-5/12 md:w-6/12 sm:w-8/12 px-5 checkout-content">
                  <PostInfo
                    loading={loading}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    coverInfo={coverInfo}
                    cartInfo={cartInfo}
                    handleSelectPost={handleSelectPost}
                    handleProcessPostSelection={handleProcessPostSelection}
                    goPreviousStep={goPreviousStep}
                  />
                </div>
              )}

              {checkoutFormStep === 3 && (
                <div className="lg:w-5/12 md:w-6/12 sm:w-8/12 px-5 checkout-content">
                  <CheckoutInfo
                    cartInfo={cartInfo}
                    relatedProductsList={relatedProductsList}
                    addItemToCart={addItemToCart}
                    deleteItemFromCart={deleteItemFromCart}
                    createNewOrder={createNewOrder}
                    createNewOrderWithCrypto={createNewOrderWithCrypto}
                    userEmail={userEmail}
                    userName={userName}
                    profileName={profileName}
                    setCheckoutFormStep={setCheckoutFormStep}
                    loading={loading}
                    stripeErrorMessage={stripeErrorMessage}
                    setStripeErrorMessage={setStripeErrorMessage}
                    coverInfo={coverInfo}
                    userIp={userIp}
                    utmSource={document.cookie.replace(
                      /(?:(?:^|.*;\s*)document_referrer\s*\=\s*([^;]*).*$)|^.*$/,
                      "$1"
                    )}
                    utmMedium={getQueryParam("utm_medium", location)}
                    paymentError={paymentError}
                    setCardObject={setCardObject}
                    setPaymentObject={setPaymentObject}
                    goPreviousStep={goPreviousStep}
                    cryptoLoading={cryptoLoading}
                  />
                </div>
              )}

            </center>
            <div style={{ marginBottom: "20px" }}>
              <center>
                <img
                  src={TrustIcons}
                  className="mt-4 w-100 payment-icons"
                  alt="icons of trust"
                />
              </center>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Checkout
